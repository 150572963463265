import { isSiteSecure, createCookie, getCookie } from './utilities.cookies.js';

function addScript() {
    var tag = document.createElement('script');
    tag.type = 'text/javascript';
    tag.async = true;
    tag.src = 'https://www.youtube.com/iframe_api';
    var script = document.getElementsByTagName('script')[0];
    script.parentNode.insertBefore(tag, script);
}

function initialisePlayers() {
    var youtubePlayers = document.querySelectorAll('.youtube-player');

    if (youtubePlayers.length !== 0) {
        addScript();

        window.onYouTubeIframeAPIReady = function() {
            for (var i = 0; i < youtubePlayers.length; i++) {
                // Append a play button
                var button = document.createElement('div');
                button.className = 'youtube-player__play-button';
                youtubePlayers[i].appendChild(button);

                // Get the thumbnail source
                var thumbSource = "https://img.youtube.com/vi/" + youtubePlayers[i].dataset.id + "/hqdefault.jpg";

                // Load the image asynchronously
                var image = new Image();
                image.src = thumbSource;

                image.addEventListener("load", function() {
                    youtubePlayers[i].appendChild(image);
                }(i));

                youtubePlayers[i].addEventListener("click", activatePlayer);
            }
        }
    }
}

function activatePlayer(e) {
    var playerFrame = this;
    var id = playerFrame.dataset.id;
    var autoplay = playerFrame.dataset.autoplay || 1;
    var playonce = playerFrame.dataset.playonce || 0;
    var color = playerFrame.dataset.color || 'red';
    var controls = playerFrame.dataset.controls || 0;
    var fullscreen = playerFrame.dataset.fullscreen || 0;
    var modestbranding = playerFrame.dataset.modestbranding || 0;
    var related = playerFrame.dataset.rel || 0;
    var showinfo = playerFrame.dataset.showinfo || 0;
    var mute = playerFrame.dataset.mute || 0;
    var height = playerFrame.dataset.height || '390';
    var width = playerFrame.dataset.width || '640';
    var privacyEnhanced = playerFrame.dataset.privacy || 0;

    var options = {
        height: height,
        width: width,
        videoId: id,
        playerVars: {
            color: color,
            controls: controls,
            fs: fullscreen,
            modestbranding: modestbranding,
            rel: related,
            showinfo: showinfo
        },
        origin: window.location.host,
        events: {
            'onReady': function(e) {
                if (mute == 1) {
                    e.target.mute();
                }
                if (autoplay == 1) {
                    e.target.playVideo();
                }
            }
        }
    };

    if (privacyEnhanced === 1) {
        options.host = 'https://www.youtube-nocookie.com';
    }

    var player = new YT.Player(playerFrame, options);
}

initialisePlayers();

//import $ from 'jQuery';
//


//function setPlayOnceCookie() {
//    createCookie('ytplayonce', true, 365, true, 'Strict', isSiteSecure());
//};

//// Check to see if the video has been shown
//function checkPlayOnceCookie() {
//    // If cookie is set to true
//    if (getCookie('ytplayonce') === 'true') {
//        return true;
//    }
//    return false;
//};



//function initialisePlayers() {
//    var youTubePlayers = $('.js__ytplayer');
//    if (youTubePlayers.length !== 0) {
//        addScript();



//        window.onYouTubeIframeAPIReady = function() {
//            youTubePlayers.each(function(idx, el) {
//                var playerFrame = $(el);
//                var id = playerFrame.data('id');
//                var autoplay = playerFrame.data('autoplay') || 0;
//                var playonce = playerFrame.data('playonce') || 0;
//                var color = playerFrame.data('color') || 'red';
//                var controls = playerFrame.data('controls') || 0;
//                var fullscreen = playerFrame.data('fullscreen') || 0;
//                var modestbranding = playerFrame.data('modestbranding') || 0;
//                var related = playerFrame.data('rel') || 0;
//                var showinfo = playerFrame.data('showinfo') || 0;
//                var mute = playerFrame.data('mute') || 0;
//                var height = playerFrame.data('height') || '390';
//                var width = playerFrame.data('width') || '640';
//                var privacyEnhanced = playerFrame.data('privacy') || 0;

//                // Cookie based playonce functionality
//                if (autoplay == 1 && playonce == 1) {
//                    if (checkPlayOnceCookie()) {
//                        autoplay = 0;
//                    } else {
//                        setPlayOnceCookie();
//                    }
//                }

//                var options = {
//                    height: height,
//                    width: width,
//                    videoId: id,
//                    playerVars: {
//                        color: color,
//                        controls: controls,
//                        fs: fullscreen,
//                        modestbranding: modestbranding,
//                        rel: related,
//                        showinfo: showinfo
//                    },
//                    origin: window.location.host,
//                    events: {
//                        'onReady': function(e) {
//                            if (mute === 1) {
//                                e.target.mute();
//                            }
//                            if (autoplay === 1) {
//                                e.target.playVideo();
//                            }
//                        }
//                    }
//                };

//                if (privacyEnhanced === 1) {
//                    options.host = 'https://www.youtube-nocookie.com';
//                }

//                var player = new YT.Player(el, options);
//            });
//        };
//    }
//}

//initialisePlayers();

//(function closure(root, factory) {
//    var utilities = App.Utilities.Namespace.createNamespace('App.Utilities');
//    utilities.YoutubePlayer = factory(root, window, jQuery, App.Utilities.CookieManager);
//}(this, function (root, win, $, cookies) {
//    var private = {};
//    var public = {};

//    //private.setPlayOnceCookie = function () {
//    //    cookies.create('ytplayonce', true, 365, false);
//    //};

//    //// Check to see if the popup has been shown
//    //private.checkPlayOnceCookie = function () {
//    //    // If cookie is set to true
//    //    if (cookies.get('ytplayonce') === 'true') {
//    //        return true;
//    //    }
//    //    return false;
//    //};

//    //private.addScript = function() {
//    //    var tag = document.createElement('script');
//    //    tag.type = 'text/javascript';
//    //    tag.async = true;
//    //    tag.src = 'https://www.youtube.com/iframe_api';
//    //    var script = document.getElementsByTagName('script')[0];
//    //    script.parentNode.insertBefore(tag, script);
//    //}

//    //private.initialise = function () {
//    //    var youTubePlayers = $('.js__ytplayer');
//    //    if (youTubePlayers.length !== 0) {
//    //        private.addScript();

//    //        win.onYouTubeIframeAPIReady = function () {
//    //            youTubePlayers.each(function (idx, el) {
//    //                var playerFrame = $(el);
//    //                var id = playerFrame.data('id');
//    //                var autoplay = playerFrame.data('autoplay') || 0;
//    //                var playonce = playerFrame.data('playonce') || 0;
//    //                var color = playerFrame.data('color') || 'red';
//    //                var controls = playerFrame.data('controls') || 0;
//    //                var fullscreen = playerFrame.data('fullscreen') || 0;
//    //                var modestbranding = playerFrame.data('modestbranding') || 0;
//    //                var related = playerFrame.data('rel') || 0;
//    //                var showinfo = playerFrame.data('showinfo') || 0;
//    //                var mute = playerFrame.data('mute') || 0;
//    //                var height = playerFrame.data('height') || '390';
//    //                var width = playerFrame.data('width') || '640';
//    //                var privacyEnhanced = playerFrame.data('privacy') || 0;

//    //                // Cookie based playonce functionality
//    //                if (autoplay == 1 && playonce == 1) {
//    //                    if (private.checkPlayOnceCookie()) {
//    //                        autoplay = 0;
//    //                    } else {
//    //                        private.setPlayOnceCookie();
//    //                    }
//    //                }

//    //                var options = {
//    //                    height: height,
//    //                    width: width,
//    //                    videoId: id,
//    //                    playerVars: {
//    //                        color: color,
//    //                        controls: controls,
//    //                        fs: fullscreen,
//    //                        modestbranding: modestbranding,
//    //                        rel: related,
//    //                        showinfo: showinfo
//    //                    },
//    //                    events: {
//    //                        'onReady': function (e) {
//    //                            if (mute === 1) {
//    //                                e.target.mute();
//    //                            }
//    //                            if (autoplay === 1) {
//    //                                e.target.playVideo();
//    //                            }
//    //                        }
//    //                    }
//    //                };

//    //                if (privacyEnhanced === 1) {
//    //                    options.host = 'https://www.youtube-nocookie.com';
//    //                }

//    //                var player = new YT.Player(el, options);
//    //            });
//    //        };
//    //    }
//    //}

//    public.init = function() {
//        private.initialise();
//    }


//    public.init();
//}));