"use strict";

import $ from 'jQuery';

/* ---------------------------------------------------------------------------------------- */
/* #region Overwrite Form Submission to block the form button                               */
/* ---------------------------------------------------------------------------------------- */

function disableSubmitButton(evt) {
    var $thisForm = $(this);
    var $submitButton = $('button[type="submit"]', $thisForm);
    var btnContent = $submitButton.html();

    // Block the button
    $submitButton.attr('disabled', 'disabled')
        .addClass('disabled')
        .html('Please wait <i class="fa fa-spinner fa-pulse"></i>');

    if (!$thisForm.valid()) {
        // Enable the button
        $submitButton.removeAttr('disabled')
            .removeClass('disabled')
            .html(btnContent);
    }
}

$('.form-disable-on-submit').off('submit', disableSubmitButton).on('submit', disableSubmitButton);

/* ---------------------------------------------------------------------------------------- */
/* #endregion                                                                               */
/* ---------------------------------------------------------------------------------------- */