import $ from 'jQuery';
import { isSiteSecure, createCookie, getCookie } from './utilities.cookies.js';

(function () {
    "use strict";

    // Set the cookie
    function setConsentCookie () {
        createCookie('cookiebar', true, 365, false, 'Strict', isSiteSecure());
    };

    // Check to see if the popup has been shown
    function checkConsentCookie () {
        // If cookie is set to true
        if (getCookie('cookiebar') === 'true') {
            return true;
        }

        return false;
    };

    function getCookiePlacement () {
        return $('body').data('cookiebar-placement') || 'bottom';
    };

    function setBodyPadding () {
        var cookieMessage = $('.cookie-message');

        // We only want to alter body padding if the message is visable
        if (cookieMessage.hasClass('cookie-message--visible')) {
            var body = $('body');
            var cookieBarHeight = cookieMessage.outerHeight();
            var cookiePlacement = getCookiePlacement();

            if (cookiePlacement === 'bottom') {
                body.css(
                    'padding-bottom', cookieBarHeight
                );
            }
        }
    };

    function removeBodyPadding () {
        var cookiePlacement = getCookiePlacement();
        var body = $('body');
        if (cookiePlacement === 'bottom') {
            body.css({
                'transition': 'padding-bottom ease 1s',
                'padding-bottom': ''
            });
        };
    };


    function initCookieMessage () {
        var cookieMessage = $('.cookie-message');
        var cookiePlacement = getCookiePlacement();
        var body = $('body');

        cookieMessage
            .addClass('cookie-message--' + cookiePlacement)
            .addClass('cookie-message--visible');

        body.addClass('cookie-message-visible--' + cookiePlacement);

        // Pad the body depending upon where the cookie message is
        setBodyPadding();
        $(window).resize(setBodyPadding);

        var closeButton = cookieMessage.find('.cookie-message__close');
        closeButton.on('click', function () {
            cookieMessage.addClass('cookie-message--closing');
            $(document).trigger('cookieMessageClosing');

            body.addClass('cookie-message-visible--closing');
            setConsentCookie();
            setTimeout(function () {
                cookieMessage
                    .removeClass('cookie-message--visible')
                    .removeClass('cookie-message--closing');
                body
                    .removeClass('cookie-message-visible--' + cookiePlacement)
                    .removeClass('cookie-message-visible--closing');
            }, 500);

            closeButton.off('click');
        });
    }

    function initialise (message) {
        // Check to see if the cookie has already been consented to
        var consented = checkConsentCookie();

        if (!consented) {
            // Otherwise we look for a cookie panel
            var cookieMessage = $('.cookie-message');
            if (cookieMessage.length !== 0) {
                initCookieMessage();
            }

            $(document).on('cookieMessageClosing', removeBodyPadding);
        }
    };


    $(function() {
        initialise();
    });
})();