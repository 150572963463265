'use strict';

// Import our custom CSS
import '../css/style.scss';

// Reference jQuery
import $ from 'jQuery';

// Import only the Bootstrap components we need
import { Util, Collapse, Dropdown, OffCanvas } from 'bootstrap';

// Cookie notifications
import '../common/js/notifications.cookiebar.js';

// Phone buttons adjustments
import '../common/js/interface.phonebutton.js';

// Lazy load images
import lazySizes from 'lazysizes';

// Jarallax
import { jarallax } from 'jarallax';

// Scrollreveal
import '../common/js/scrollreveal.auto.js';

// Youtube player
import '../common/js/interface.youtubeplayer.js';

// Site code
import './openinghours.js';
import './forms.js';


// Initialise jarallax
jarallax(document.querySelectorAll('.parallax-container'), {
    speed: 0.5,
    imgElement: '.parallax-container__img'
});
