import $ from 'jquery';
// Import only the Bootstrap components we need
import { Modal } from 'bootstrap';

(function() {
    "use strict";

    var $modal = $('#openinghours-modal');

    $(".js__openinghours").on('click',
        function(e) {
            e.preventDefault();
            $modal.modal('show');
        });
})();