import $ from 'jQuery';

(function() {
    "use strict";
    var cookieBar, phoneButton;

    // Offset the phone button
    function offsetPhoneButton() {
        if (cookieBar.hasClass('cookie-message--visible')) {
            var cookieBarHeight = cookieBar.outerHeight();
            phoneButton.css('bottom', cookieBarHeight + 20);
        }
    }

    function movePhoneButton(e) {
        phoneButton.css({
            'transition': 'bottom ease 1s',
            'bottom': 20
        });
    };

    function initPhoneButton() {
        offsetPhoneButton();
        $(window).resize(offsetPhoneButton);
        $(document).on('cookieMessageClosing', movePhoneButton);
    };

    function initialise() {
        // Adjust position of the phone indicator dependant on if the cookie message is showing
        cookieBar = $('.cookie-message--bottom');
        phoneButton = $('.contact-float');
        if (cookieBar.length !== 0 && cookieBar.hasClass('cookie-message--visible') && phoneButton !== 0) {
            initPhoneButton();
        }
    };

    $(function() {
        initialise();
    });
})();