import $ from 'jQuery';
import scrollreveal from 'scrollreveal';

var reveals;

function animate(idx, obj) {
    var reveal = $(obj);
    var rData = reveal.data();
    var element = null;

    var srOptions = {
        delay: rData.srDelay || 500,
        distance: rData.srDistance || 0,
        duration: rData.srDuration || 500,
        easing: rData.srEasing || 'ease-out',
        interval: rData.srInterval || 0,
        opacity: rData.srOpacity || 0,
        origin: rData.srOrigin || 'left',
        scale: rData.srScale || 1,
        mobile: parseInt(rData.srMobile) || 1,
        viewFactor: rData.srViewfactor || 0,
        viewOffset: {
            //top: initOffset
        }
    }

    if (rData.srGroup) {
        element = rData.srGroup;
    } else {
        element = reveal[0];
    }

    scrollreveal().reveal(element, srOptions);
}

export function initScrollReveal() {
    reveals = $('[data-sr="true"]');
    if (reveals.length) {
        var srOptions = {};
        scrollreveal(srOptions);
        reveals.each(animate);
    }
}